<template>
  <div id="introduce">
    <div class="history">
      <div class="introduce">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E9%9D%92%E7%9F%B3%E9%9B%95%E9%B1%BC%E7%BA%B9%E7%A0%9A.jpg"
          alt=""
        />
        <span
          >{{ inkstoneList.data[0].name }} <br /><br />
          {{ inkstoneList.data[0].introduce }}</span
        >
      </div>
      <div class="introduce" id="test">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E7%AE%95%E5%BD%A2%E9%99%B6%E7%A0%9A.jpg"
          alt=""
        />
        <span
          >{{ inkstoneList.data[1].name }} <br /><br />
          {{ inkstoneList.data[1].introduce }}</span
        >
      </div>
      <div class="introduce">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E7%99%BD%E9%B9%A4%E5%B1%B1%E4%BA%BA%E6%AC%BE%E7%AB%AF%E7%9F%B3%E7%A0%9A.jpg"
          alt=""
        />
        <span
          >{{ inkstoneList.data[2].name }} <br /><br />
          {{ inkstoneList.data[2].introduce }}</span
        >
      </div>
      <div class="introduce">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E7%AB%AF%E7%9F%B3%E8%A5%BF%E5%BA%90%E8%AF%BB%E4%B9%A6%E5%9B%BE%E7%A0%9A.jpg"
          alt=""
        />
        <span
          >{{ inkstoneList.data[3].name }} <br /><br />
          {{ inkstoneList.data[3].introduce }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Introduce",
  props: ["inkstoneList"],
};
</script>

<style scoped>
#introduce {
  margin-left: 10%;
  margin-right: 10%;
}
.history {
  display: flex;
  margin: 2%;
}
.history {
  min-height: 20vh;
}
@media screen and (max-width: 2000px) {
  .history {
    font-size: 15px;
  }
}
@media screen and (min-width: 2000px) {
  .history {
    font-size: 18px;
  }
}
.history .introduce {
  display: flex;
  flex: 1;
  display: flex;
  align-items: center;
}
.history .introduce img,
.history .introduce span {
  width: 50%;
  aspect-ratio: 1 / 1; /* 设置宽高比为 1:1 */
}
.history .introduce img {
  /* height: 50%; */
  border-radius: 50%;
  margin-right: 5px;
}

@media screen and (max-width: 500px) {
  .history {
    display: block;
    margin: 2%;
  }
  .introduce {
    margin-top: 40px;
  }
  span {
    /* margin-top: 10px; */
    font-size: 11px;
  }
}
</style>