<template>
  <div id="main">
    <div class="technology-two">
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E6%AA%80%E9%A6%99%E6%9C%A8%E5%BD%A9%E7%94%BB%E7%A6%8F%E5%AF%BF%E7%AC%94.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ quillList[4].name }}<br />
          <br />{{ quillList[4].content }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E7%AB%B9%E7%AE%A1%E5%85%B0%E4%BA%AD%E8%B5%8F%E7%8B%BC%E6%AF%AB%E7%AC%94.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ quillList[5].name }}<br />
          <br />{{ quillList[5].content }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E5%BD%A9%E6%BC%86%E8%8A%B1%E5%8D%89%E7%B4%AB%E6%AF%AB%E7%AC%94.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ quillList[6].name }}<br />
          <br />{{ quillList[6].content }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E7%AB%B9%E9%9B%95%E4%BA%91%E9%BE%99%E7%AE%A1%E8%B2%82%E6%AF%AB%E7%AC%94.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ quillList[7].name }}<br />
          <br />{{ quillList[7].content }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "quillContent",
  props: ["quillList"],
};
</script>
  
  <style scoped>
#main {
  margin-left: 10%;
  margin-right: 10%;
}
#black {
  background-color: rgb(46, 45, 50);
  color: white;
}
.technology-two {
  display: flex;
  margin: 0px 4%;
  margin-bottom: 1%;
}
@media screen and (max-width: 2000px) {
  .technology-two {
    height: 70vh;
  }
}
@media screen and (min-width: 2000px) {
  .technology-two {
    height: 35vh;
  }
}
.technology-two .histor {
  flex: 1;
  margin: 0 1%;
}
.technology-two .histor {
  margin-top: 1%;
  position: relative;
}
.technology-two .histor .background {
  position: absolute;
  width: 100%;
  height: 45%;
}
.technology-two .introduce {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 47%;
  /* display: flex; */
}
.technology-two .histor .background img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .brew {
    display: none;
  }
  .technology-two {
    display: block;
    height: auto;
  }
  .technology-two .histor {
    position: Static;
  }
  .technology-two .histor .background {
    position: Static;
    width: 100%;
    height: 45%;
  }
  .technology-two .histor {
    position: Static;
  }
  .technology-two .introduce {
    position: Static;
  }
}
</style>
  