<template>
  <div>
    <div class="bg">
      <Title2></Title2>
      <div id="main">
        <div class="fl img"></div>
        <div class="fr content">
          <el-form
            label-position="right"
            :label-width="dynamicLabelWidth"
            :model="user"
          >
            <el-form-item label="用户名:">
              <el-input
                v-model="user.name"
                placeholder="请输入你的用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码:">
              <el-input
                v-model="user.password"
                placeholder="请输入你的密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱:">
              <el-input
                v-model="user.email"
                placeholder="请输入你的邮箱号"
              ></el-input>
            </el-form-item>
            <el-form-item label="个人简介:">
              <el-input
                type="textarea"
                v-model="user.personalProfile"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码:">
              <el-input v-model="captcha" placeholder="验证码" :disabled="c">
                <template slot="append"
                  ><el-button
                    type="primary"
                    class="captcha-btn"
                    @click="getCaptcha"
                    >获取验证码</el-button
                  ></template
                >
              </el-input>
            </el-form-item>
            <el-form-item class="b">
              <el-button class="btn" @click="register()">注册</el-button>
              <router-link style="color: #6e6452" to="/login" class="fr"
                >没有账号？去登录</router-link
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import instance from "@/api/home";

export default {
  data() {
    return {
      user: {
        name: "",
        password: "",
        personalProfile: "",
        email: "",
      },
      captcha: "",
      c: true,
    };
  },
  methods: {
    register() {
      if (
        this.user.name == "" ||
        this.user.password == "" ||
        this.captcha == ""
      ) {
        alert("信息不全");
      } else {
        instance
          .post("/user/enroll", this.user, {
            params: { captcha: this.captcha },
          })
          .then((res) => {
            if (res.code == 1) {
              alert(res.message);
            } else {
              alert(res.data);
            }
          });
      }
    },
    getCaptcha() {
      const email = this.user.email;
      if (email) {
        alert(email);
        this.c = false;
        instance
          .post("user/enroll/captcha", email, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((result) => {
            console.log(result.data);
          });
      } else {
        alert("请填写邮箱号");
      }
    },
  },
  mounted() {
    // this.login();
  },
  computed: {
    dynamicLabelWidth() {
      // 在这里根据屏幕宽度动态计算 label 的宽度
      if (window.innerWidth < 500) {
        return "5rem"; // 在小屏幕设备上设置较小的 label 宽度
      } else {
        return "8rem"; // 在大屏幕设备上设置较大的 label 宽度
      }
    },
  },
};
</script>

<style scoped>
.bg {
  background: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Register/%E8%83%8C%E6%99%AF%E5%9B%BE%E7%89%87.png");
}

#main {
  width: 80%;
  margin: auto;
  height: calc(100vh - 121px);
  /* background: #999; */
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.img {
  margin: 4%;
  width: 42%;
  height: 50vh;
  background-image: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Register/%E4%B9%A6%E6%B3%95.jpg");
  background-size: cover;
  background-position: center;
}

.content {
  width: 34%;
  padding: 2%;
  padding-bottom: 5px;
  padding-right: 8%;
  min-height: 50vh;
  background: #eee;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #000;
  margin-right: 4%;
  margin-top: 4%;
}

.btn {
  background: #6e6452;
  color: #fff;
  border: 0;
}

.btn:hover {
  background: #8c7e64;
  color: #fff;
  border: 0;
}

.btn:focus {
  background: #8c7e64;
  color: #fff;
  border: 0;
}

@media screen and (max-device-width: 500px) {
  .img {
    display: none;
  }

  .content {
    float: none;
    width: 92%;
    margin: auto;
  }
  #main {
    padding-top: 2em;
    height: calc(100vh - 60px - 2em);
  }
}
</style>