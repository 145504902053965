<template>
  <div id="introduce">
    <div class="history">
      <div class="introduce">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Paper/%E9%BB%84%E8%89%B2%E5%86%99%E7%BB%8F%E7%BA%B8.jpg"
          alt=""
        />
        <span
          >{{ paperList.data[0].name }} <br /><br />
          {{ paperList.data[0].content }}
        </span>
      </div>
      <div class="introduce">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Paper/%E7%AB%B9%E7%BA%B8.jpg"
          alt=""
        />
        <span
          >{{ paperList.data[1].name }}<br /><br />
          {{ paperList.data[1].content }}
        </span>
      </div>
      <div class="introduce">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Paper/%E4%B9%BE%E9%9A%86%E9%9B%AA%E7%BB%B5%E7%BA%AF%E5%98%8F%E8%AF%97%E7%AC%BA.jpg"
          alt=""
        />
        <span
          >{{ paperList.data[6].name }}<br /><br />
          {{ paperList.data[6].content }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Introduce",
  props: ["paperList"],
};
</script>

<style scoped>
#introduce {
  margin-left: 10%;
  margin-right: 10%;
}
.history {
  display: flex;
  margin-bottom: 2%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
  min-height: 25vh;
}
/* @media screen and (max-width: 2000px) {
  .history {
    height: 49vh;
    font-size: 14px;
  }
}
@media screen and (min-width: 2000px) {
  .history {
    height: 25vh;
    font-size: 18px;
  }
} */
.history .introduce {
  display: flex;
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 1%;
}
.history .introduce img,
.history .introduce span {
  width: 50%;
}
.history .introduce img {
  height: 100%;
  margin-right: 2%;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 500px) {
  .history {
    display: block;
    margin: 2%;
  }
  .introduce {
    margin-top: 40px;
  }
  span {
    /* margin-top: 10px; */
    font-size: 11px;
  }
  .history .introduce {
    display: block;
  }
  .title {
    font-size: 16px;
  }
}
</style>