<template>
  <div :style="bgStyle" id="main">
    <div class="containerLine">
      <div class="line"></div>
      <div class="dot" style="--n: 0" id="dot1" @click="clickDot(4)">
        <span>1</span>
      </div>
      <div class="dot" style="--n: 1" id="dot2" @click="clickDot(5)">
        <span>2</span>
      </div>
      <div class="dot" style="--n: 2" id="dot3" @click="clickDot(6)">
        <span>3</span>
      </div>
      <div class="dot" style="--n: 3" id="dot4" @click="clickDot(7)">
        <span>4</span>
      </div>
    </div>

    <Introduce class="content" :inputName="getActiveId"></Introduce>

    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(card, index) in imgsCard"
          :key="index"
        >
          <div
            class="card"
            :style="{ backgroundImage: 'url(' + card.src + ')' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import Introduce from "./Introduce.vue";
export default {
  name: "Content",
  components: {
    Introduce,
  },
  data() {
    return {
      coverImgUrl:"https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E7%AC%94.jpg",
      myswiper: null,
      t: 0,
      name: "12345",
      imgsBG: [
        {
          img: new Image(),
          src: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E7%AC%94.jpg",
        },
        {
          img: new Image(),
          src: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E5%A2%A8.jpg",
        },
        {
          img: new Image(),
          src: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E7%BA%B8.jpg",
        },
        {
          img: new Image(),
          src: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E7%A0%9A.jpg",
        },
      ],
      imgsCard: [
        {
          img: new Image(),
          src: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E7%AC%94-card.jpg",
        },
        {
          img: new Image(),
          src: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E5%A2%A8-card.jpeg",
        },
        {
          img: new Image(),
          src: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E7%BA%B8-card.jpg",
        },
        {
          img: new Image(),
          src: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/home/%E7%A0%9A-card.jpg",
        },
      ],
    };
  },
  mounted() {
    var myswiper = new Swiper(".swiper-container", {
      on: {
        slideChange() {
          // console.log('当前的slide序号是' + this.activeIndex);//或者swiper.activeIndex，swiper与this都可指代当前swiper实例

          //清空原本active的dot的样式
          const dots = document.querySelectorAll(".dot");
          dots.forEach((dot) => {
            if (dot.classList.contains("active")) {
              dot.classList.remove("active");
              dot.querySelector("span").style.display = "none";
            }
          });

          //使dot随着swiper发生变化而发生变化
          const updateDot = (dot) => {
            document.getElementById(dot).classList.add("active");
            document.getElementById(dot).querySelector("span").style.display =
              "block";
          };
          //swiper发生变化后dot也发生变化
          if (this.activeIndex == 4 || this.activeIndex == 8) {
            updateDot("dot1");
          } else if (this.activeIndex == 5 || this.activeIndex == 1) {
            updateDot("dot2");
          } else if (this.activeIndex == 6 || this.activeIndex == 2) {
            updateDot("dot3");
          } else if (this.activeIndex == 7 || this.activeIndex == 3) {
            updateDot("dot4");
          }
        },
      },
      // direction: 'vertical', // 垂直切换选项
      loop: true, // 循环模式选项
      slidesPerView: "auto",
      slidesPerView: 3.5,
      watchSlidesProgress: true,
      loopFillGroupWithBlank: true,
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    this.myswiper = myswiper;
    this.initDot();
  },
  methods: {
    setDot(index) {
      this.myswiper.progress = index;
    },
    clickDot(index) {
      this.myswiper.slideTo(index, 100, false);
    },
    initDot() {
      const dots = document.querySelectorAll(".dot");

      dots.forEach((dot) => {
        dot.addEventListener("click", () => {
          if (!dot.classList.contains("active")) {
            dots.forEach((otherDot) => {
              if (otherDot !== dot && otherDot.classList.contains("active")) {
                otherDot.classList.remove("active");
                // otherDot.querySelector('span').style.display = 'none';
              }
            });

            dot.classList.add("active");
            // dot.querySelector('span').style.display = 'block';
          }
        });
      });
    },
    setBgStyle() {
      var index = this.getActiveId;
      this.coverImgUrl = this.imgsBG[index - 1].img.src;
    },
    cardImg(index) {
      return {
        backgroundImage: "url(" + this.imgsCard[index - 1].img.src + ")",
      };
    },
    preloadImages() {
      this.imgsBG.forEach((item) => {
        item.img.src = item.src;
      });
    },
  },
  created() {
    this.preloadImages();
  },
  computed: {
    getSwiper() {
      if (this.myswiper != null) {
        return this.myswiper.progress;
      } else {
        return null;
      }
    },
    bgStyle() {
      this.setBgStyle();
      return {
        backgroundImage: "url(" + this.coverImgUrl + ")",
      };
    },
    getActiveIndex() {
      if (this.myswiper != null) {
        var index = this.myswiper.activeIndex;
        return index;
      }
    },
    getActiveId() {
      if (this.myswiper != null) {
        var index = this.myswiper.activeIndex;
        if (index == 5 || index == 1) {
          return 2;
        } else if (index == 6 || index == 2) {
          return 3;
        } else if (index == 7 || index == 3) {
          return 4;
        }
      }
      return 1;
    },
  },
};
</script>







<style scoped>
#main {
  width: 100%;
  padding-top: 9vh;
  height: 91vh;
  display: flex;
  background-position: center;
  background-size: cover;
}

.content {
  margin: auto;
  margin-top: 15vh;
  width: 30%;
  background: rgba(0, 0, 0, 0.3);
  height: 25em;
}

.swiper-container {
  width: 40vw;
  height: 25vw;
  margin-top: 25vh;
  margin-right: 2%;
  float: right;
}

@media screen and (min-device-width: 800px) and (max-device-width: 1200px) {
  .content {
    width: 40%;
    height: 45vw;
  }

  .swiper-container {
    width: 50vw;
    height: 30vw;
    margin-right: 0%;
  }
}

span {
  color: #000;
}

.swiper-slide {
  width: 25%;
}

.swiper-slide > div {
  transform: scale(0.8);
}

.swiper-wrapper > div > div {
  width: 100%;
  height: 70%;
  box-shadow: 0px 10px 10px black;
  border-radius: 7px;
}

.swiper-slide-active > div,
.swiper-slide-duplicate-active > div {
  transform: scale(0.95);
}

.card {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
}

.containerLine {
  position: relative;
  height: 80%;
  float: left;
  /* padding: 25px; */
  padding-left: 5%;
  margin-top: 5%;
}

.line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background-color: white;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 5px;
  position: absolute;
  top: calc(var(--n) * (100% / 3));
  /* 计算每个圆点在竖线上的位置 */
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.dot span {
  display: none;
  font-size: 15px;
}

.dot.active span {
  display: block;
}

.dot.active {
  width: 15px;
  height: 15px;
}

@media screen and (max-device-width: 600px) {
  #main {
    /* margin-top: 18vh; */
    width: 100%;
    display: block;
  }

  .content {
    width: 75%;
    height: 21.5em;
    padding: 0%;
    margin: auto;
    margin-top: 2vh;
  }

  .swiper-container {
    position: absolute;
    bottom: 10px;
    width: 94vw;
    height: 52vw;
    padding-top: 10px;
    margin: 3vw;
    border: 5px;
  }

  .containerLine {
    height: 21.5em;
  }
  .swiper-slide-active > div,
  .swiper-slide-duplicate-active > div {
    transform: scale(1);
  }
}

.dot > span {
  color: #fff;
}
</style>