<template>
  <div id="step">
    <div class="technology-two">
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E6%B1%9F%E6%AD%A3%E7%8E%84%E7%8E%89%E5%A2%A8.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ InkList[4].name }}<br />
          <br />{{ InkList[4].origin }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E6%B1%AA%E6%96%87%E5%AE%AA%E9%9D%92%E9%BA%9F%E9%AB%93%E5%A2%A8.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ InkList[5].name }}<br />
          <br />{{ InkList[5].origin }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E5%85%B0%E5%8F%B0%E7%B2%BE%E8%8B%B1%E5%A2%A8.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ InkList[6].name }}<br />
          <br />{{ InkList[6].origin }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E5%85%B0%E4%BA%AD%E4%BF%AE%E8%A4%89%E7%99%BD%E5%BE%A1%E5%A2%A8.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ InkList[7].name }}<br />
          <br />{{ InkList[7].origin }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Step",
  props: ["InkList"],
};
</script>
  
  <style scoped>
#step {
  margin-left: 10%;
  margin-right: 10%;
}
#black {
  background-color: rgb(46, 45, 50);
  color: white;
}
.technology-two {
  display: flex;
  margin: 0px 4%;
  margin-bottom: 1%;
}
@media screen and (max-width: 2000px) {
  .technology-two {
    height: 70vh;
  }
}
@media screen and (min-width: 2000px) {
  .technology-two {
    height: 33.1vh;
  }
}
.technology-two .histor {
  flex: 1;
  margin: 0 1%;
}
.technology-two .histor {
  margin-top: 1%;
  position: relative;
}
.technology-two .histor .background {
  position: absolute;
  width: 100%;
  height: 45%;
}
.technology-two .introduce {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 47%;
  display: flex;
}
.technology-two .histor .background img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .brew {
    display: none;
  }
  .technology-two {
    display: block;
    height: auto;
  }
  .technology-two .histor {
    position: Static;
  }
  .technology-two .histor .background {
    position: Static;
    width: 100%;
    height: 45%;
  }
  .technology-two .introduce {
    position: Static;
  }
}
</style>
  