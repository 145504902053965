<template>
  <div id="background">
    <div class="background-img">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "background",
};
</script>

<style scoped>
.background-img {
  height: 50vh;
}
@media screen and (max-width: 500px) {
  .background-img {
    height: 36vh;
  }
}
.background-img div {
  background-image: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E6%AF%9B%E7%AC%94%E8%83%8C%E6%99%AF%E5%9B%BE.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>