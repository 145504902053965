import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
//引入小仓库
import home from './home/home'
import Inkstone from './Inkstone/Inkstone'
import Paper from './Paper/Paper'
import quill from './quill/quill'
import Ink from './Ink/Ink'
//对外暴露store
export default new Vuex.Store({
    //实现Vuex仓库模块式开发存储数据
    modules:{
        home,
        Inkstone,
        Paper,
        quill,
        Ink
    }
})