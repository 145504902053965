<template>
  <div id="background">
    <div class="background-img">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
    name:"background"
}
</script>

<style scoped>
@media screen and (max-width: 2000px) {
  .background-img {
    height: 55vh;
  }
}
@media screen and (min-width: 2000px) {
  .background-img {
    height: 30vh;
  }
}
.background-img div {
  background-image: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E5%BE%BD%E5%A2%A8%E8%83%8C%E6%99%AF%E5%9B%BE.jpg");
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
</style>