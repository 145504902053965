<template>
  <div id="nameList">
    <div class="slot">
      <div class="slot-track">
        <div class="slot-img1-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B8%80%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img1-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B8%80%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>

      <div class="slot-track">
        <div class="slot-img2-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%BA%8C%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img2-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%BA%8C%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img3-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B8%89%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img3-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B8%89%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img4-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%9B%9B%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img4-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%9B%9B%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img5-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%BA%94%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img5-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%BA%94%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img6-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%85%AD%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img6-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%85%AD%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img7-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B8%83%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img7-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B8%83%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img8-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%85%AB%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img8-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%85%AB%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img9-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B9%9D%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img9-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B9%9D%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img10-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img10-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img11-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%B8%80%E6%A0%BC%E4%B8%8A_.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img11-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%B8%80%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img12-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%BA%8C%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img12-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%BA%8C%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img13-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%B8%89%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img13-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%B8%89%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img14-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E5%9B%9B%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img14-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E5%9B%9B%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img15-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%BA%94%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img15-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%BA%94%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img16-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E5%85%AD%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img16-two">
          <img
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E5%85%AD%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img17-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%B8%83%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img17-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%B8%83%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img18-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E5%85%AB%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img18-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E5%85%AB%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img19-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%B9%9D%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img19-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E5%8D%81%E4%B9%9D%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img20-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%BA%8C%E5%8D%81%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img20-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%BA%8C%E5%8D%81%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
      <div class="slot-track">
        <div class="slot-img21-one">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E6%A0%BC%E4%B8%8A.jpg"
            class="lazy-load" />
        </div>
        <div class="slot-img21-two">
          <img :src="imagePlaceholder"
            data-src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%BA%8C%E5%8D%81%E4%B8%80%E6%A0%BC%E4%B8%8B.jpg"
            class="lazy-load" />
        </div>
      </div>
    </div>
    <div class="title">
      <div class="title-text">
        <div class="arrow up"></div>
        <div class="text">文房四宝代表性遗产名录</div>
        <div class="arrow down"></div>
      </div>
    </div>
    <transition-group appear name="book-transition">
      <div class="book" id="book" key="book1">
        <div class="b1">
          <router-link :to="{ path: '/map', query: { componentName: '宣笔' } }"><img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE1%E5%AE%A3%E7%AC%94.jpg" /></router-link>
        </div>
        <div class="b2">
          <router-link :to="{ path: '/map', query: { componentName: '湖笔' } }"><img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE2%E6%B9%96%E7%AC%94.jpg" /></router-link>
        </div>
        <div class="b3">
          <router-link :to="{ path: '/map', query: { componentName: '侯笔' } }">
            <img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE3%E4%BE%AF%E7%AC%94.jpg" /></router-link>
        </div>
        <div class="b4">
          <router-link :to="{ path: '/map', query: { componentName: '齐笔' } }">
            <img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE4%E9%BD%90%E7%AC%94.jpg" /></router-link>
        </div>
        <div class="b5">
          <router-link :to="{ path: '/map', query: { componentName: '徽墨' } }">
            <img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE5%E5%BE%BD%E5%A2%A8.jpg" /></router-link>
        </div>
        <div class="b6">
          <router-link :to="{ path: '/map', query: { componentName: '宣纸' } }">
            <img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE6%E5%AE%A3%E7%BA%B8.jpg" /></router-link>
        </div>
        <div class="b7">
          <router-link :to="{ path: '/map', query: { componentName: '端砚' } }">
            <img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE7%E7%AB%AF%E7%A0%9A.jpg" /></router-link>
        </div>
        <div class="b8">
          <router-link :to="{ path: '/map', query: { componentName: '歙砚' } }">
            <img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE8%E6%AD%99%E7%A0%9A.jpg" /></router-link>
        </div>
        <div class="b9">
          <router-link :to="{ path: '/map', query: { componentName: '澄泥砚' } }">
            <img
              src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE9%E6%BE%84%E6%B3%A5%E7%A0%9A.jpg" /></router-link>
        </div>
      </div>
    </transition-group>
    <div class="Side-heading">

      <div class="side">
        <router-link to="/home">
          <div>首页</div>
        </router-link>
      </div>

      <div class="side">
        <router-link to="/quill">
          <div>文房四宝</div>
        </router-link>
      </div>
      <div class="side">
        <router-link :to="{ path: '/map', query: {componentName: '宣纸' } }">
          <div>寻迹</div>
        </router-link>
      </div>
      <div class="side">
        <router-link to="/forum">
          <div>论坛</div>
        </router-link>
      </div>
    </div>
    <Title id="Title"></Title>
  </div>
</template>

<script>
export default {
  name: "NameLIst",
  data() {
    return {
      showBook: false, // 初始显示书籍内容
      imgs: [
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E7%AC%AC%E4%B8%80%E6%A0%BC%E4%B8%8A.jpg",
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE2%E6%B9%96%E7%AC%94.jpg",
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE3%E4%BE%AF%E7%AC%94.jpg",
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE4%E9%BD%90%E7%AC%94.jpg",
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE5%E5%BE%BD%E5%A2%A8.jpg",
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE6%E5%AE%A3%E7%BA%B8.jpg",
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE7%E7%AB%AF%E7%A0%9A.jpg",
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE8%E6%AD%99%E7%A0%9A.jpg",
        "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E5%9B%BE9%E6%BE%84%E6%B3%A5%E7%A0%9A.jpg",
      ],
      imagePlaceholder: require('../../assets/背景图片.png'),
    };
  },
  methods: {
    lazyLoadImages() {
      const lazyImages = document.querySelectorAll('.lazy-load');

      const lazyImageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove('lazy-load');
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });

      lazyImages.forEach(image => {
        lazyImageObserver.observe(image);
      });
    }
  },
  mounted() {
    this.lazyLoadImages();
  },
};
</script>

<style scoped>
#nameList {
  height: auto;
}

#Title {
  display: none;
}

.slot {
  display: flex;
  position: fixed;
  /* 添加 fixed 定位 */
  top: 50%;
  /* 将图片容器垂直居中 */
  left: 0;
  /* 使图片容器固定在页面最左边 */
  transform: translateY(-50%);
  /* 垂直居中 */
}

.slot .slot-track div>img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.slot .slot-track {
  flex: 1;
}

.slot .slot-track div {
  height: auto;
}

.slot .slot-track .slot-img1-one {
  height: 60vh;
}

.slot .slot-track .slot-img1-two {
  height: 40vh;
}

.slot .slot-track .slot-img2-one {
  height: 80vh;
}

.slot .slot-track .slot-img2-two {
  height: 20vh;
}

.slot .slot-track .slot-img3-one {
  height: 28vh;
}

.slot .slot-track .slot-img3-two {
  height: 72vh;
}

.slot .slot-track .slot-img4-one {
  height: 12vh;
}

.slot .slot-track .slot-img4-two {
  height: 88vh;
}

.slot .slot-track .slot-img5-one {
  height: 22vh;
}

.slot .slot-track .slot-img5-two {
  height: 78vh;
}

.slot .slot-track .slot-img6-one {
  height: 50vh;
}

.slot .slot-track .slot-img6-two {
  height: 50vh;
}

.slot .slot-track .slot-img7-one {
  height: 50vh;
}

.slot .slot-track .slot-img7-two {
  height: 50vh;
}

.slot .slot-track .slot-img8-one {
  height: 28vh;
}

.slot .slot-track .slot-img8-two {
  height: 72vh;
}

.slot .slot-track .slot-img9-one {
  height: 50vh;
}

.slot .slot-track .slot-img9-two {
  height: 50vh;
}

.slot .slot-track .slot-img10-one {
  height: 60vh;
}

.slot .slot-track .slot-img10-two {
  height: 40vh;
}

.slot .slot-track .slot-img11-one {
  height: 80vh;
}

.slot .slot-track .slot-img11-two {
  height: 20vh;
}

.slot .slot-track .slot-img12-one {
  height: 88vh;
}

.slot .slot-track .slot-img12-two {
  height: 12vh;
}

.slot .slot-track .slot-img13-one {
  height: 80vh;
}

.slot .slot-track .slot-img13-two {
  height: 20vh;
}

.slot .slot-track .slot-img14-one {
  height: 22vh;
}

.slot .slot-track .slot-img14-two {
  height: 78vh;
}

.slot .slot-track .slot-img15-one {
  height: 50vh;
}

.slot .slot-track .slot-img15-two {
  height: 50vh;
}

.slot .slot-track .slot-img16-one {
  height: 80vh;
}

.slot .slot-track .slot-img16-two {
  height: 20vh;
}

.slot .slot-track .slot-img17-one {
  height: 50vh;
}

.slot .slot-track .slot-img17-two {
  height: 50vh;
}

.slot .slot-track .slot-img18-one {
  height: 28vh;
}

.slot .slot-track .slot-img18-two {
  height: 72vh;
}

.slot .slot-track .slot-img19-one {
  height: 20vh;
}

.slot .slot-track .slot-img19-two {
  height: 80vh;
}

.slot .slot-track .slot-img20-one {
  height: 80vh;
}

.slot .slot-track .slot-img20-two {
  height: 20vh;
}

.slot .slot-track .slot-img21-one {
  height: 60vh;
}

.slot .slot-track .slot-img21-two {
  height: 40vh;
}

.title {
  position: absolute;
  top: 25vh;
  bottom: 25%;
  left: 8.6%;
}

.title-text {
  writing-mode: vertical-rl;
  /* 让文字从上到下竖着写 */
  vertical-align: middle;
  font-size: 26px;
  letter-spacing: 0;
  color: white;
  position: relative;
}

.arrow {
  position: absolute;
  left: 50%;
  /* 水平居中 */
  transform: translateX(-50%);
  width: 34px;
  height: 24px;
  cursor: pointer;
  /* 添加光标样式 */
  filter: brightness(0) invert(100%);
}

.up {
  top: -30px;
  /* 上箭头在文字正上方 */
  background: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E7%AE%AD%E5%A4%B4.png") no-repeat;
}

.down {
  bottom: -30px;
  /* 下箭头在文字正下方 */
  transform: translateX(-50%) rotate(180deg);
  background: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/NameList/%E5%B0%8F%E7%AE%AD%E5%A4%B4.png") no-repeat;
}

.title-text {
  writing-mode: vertical-rl;
  /* 让文字从上到下竖着写 */
  vertical-align: middle;
  font-size: 26px;
  letter-spacing: 0;
  color: white;
  position: relative;
}

.book-transition-enter-active,
.book-transition-leave-active {
  transition: opacity 1s ease-in-out;
  /* 定义过渡效果 */
}

.book-transition-enter,
.book-transition-leave-to {
  opacity: 0;
  /* 定义进入和离开时的初始状态 */
}

.book .b1 a img {
  width: 9.5%;
  position: absolute;
  left: 14.3%;
  bottom: 33vh;
}

.book .b2 a img {
  width: 9.5%;
  position: absolute;
  left: 23.81%;
  bottom: 23vh;
}

.book .b3 a img {
  width: 9.5%;
  position: absolute;
  left: 28.6%;
  bottom: 57.34vh;
}

.book .b4 a img {
  width: 9.5%;
  position: absolute;
  left: 38.1%;
  bottom: 47.8vh;
}

.book .b5 a img {
  width: 9.5%;
  position: absolute;
  left: 38.1%;
  bottom: 10vh;
}

.book .b6 a img {
  width: 9.5%;
  position: absolute;
  left: 52.4%;
  bottom: 38.5vh;
}

.book .b7 a img {
  width: 9.5%;
  position: absolute;
  left: 66.65%;
  bottom: 38.5vh;
}

.book .b8 a img {
  width: 9.5%;
  position: absolute;
  left: 76.17%;
  bottom: 55.5vh;
}

.book .b9 a img {
  width: 9.5%;
  position: absolute;
  left: 76.17%;
  bottom: 13vh;
}

.Side-heading {
  height: 32%;
  position: absolute;
  top: 30vh;
  transform: translateX(-215%);
  transition: transform 0.5s ease, background-color 0.5s ease;
}

.Side-heading:hover {
  transform: translateX(-100%);
}

.Side-heading .side {
  height: 8vh;
  line-height: 8vh;
  text-align: center;
  border: 1px rgb(149, 162, 155) solid;
  border-radius: 10px;
  width: 200%;
  margin-top: 10%;
  margin-bottom: 10%;
  position: relative;
  left: 20px;
  background: rgb(149, 162, 155);
}

.Side-heading .side div {
  align-items: center;
  position: relative;
  left: 20px;
  color: white;
}

@media screen and (max-device-width: 500px) {

  .Side-heading,
  .arrow,
  .up,
  .down {
    display: none;
  }

  .title {
    transform: translateY(-28%);
    left: 12%;
  }

  .title-text {
    writing-mode: horizontal-tb;
  }

  .book .b1 a img {
    width: 35%;
    position: absolute;
    left: 11%;
    bottom: 48vh;
  }

  .book .b2 a img {
    width: 35%;
    position: absolute;
    left: 54%;
    bottom: 48vh;
  }

  .book .b3 a img {
    width: 35%;
    position: absolute;
    left: 11%;
    bottom: 12vh;
  }

  .book .b4 a img {
    width: 35%;
    position: absolute;
    left: 54%;
    bottom: 12vh;
  }

  .book .b5 a img {
    width: 35%;
    position: absolute;
    left: 11%;
    bottom: -24vh;
  }

  .book .b6 a img {
    width: 35%;
    position: absolute;
    left: 54%;
    bottom: -24vh;
  }

  .book .b7 a img {
    width: 35%;
    position: absolute;
    left: 11%;
    bottom: -60vh;
  }

  .book .b8 a img {
    width: 35%;
    position: absolute;
    left: 54%;
    bottom: -60vh;
  }

  .book .b9 a img {
    width: 35%;
    position: absolute;
    left: 11%;
    bottom: -96vh;
  }

  #Title {
    display: block;
  }
}
</style>
