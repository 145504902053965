<template>
  <div id="step">
    <div class="step">
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Paper/%E7%8F%8A%E7%91%9A%E8%89%B2%E5%BC%80%E5%8C%96%E7%BA%B8.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ paperList.data[2].name }}</span
          ><br /><br />
          <span>{{ paperList.data[2].content }}</span>
        </div>
      </div>
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Paper/%E8%A4%90%E8%89%B2%E8%99%8E%E7%9A%AE%E5%AE%A3%E7%BA%B8.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ paperList.data[3].name }}</span
          ><br /><br />
          <span>{{ paperList.data[3].content }}</span>
        </div>
      </div>
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Paper/%E7%BD%97%E7%BA%B9%E6%B4%92%E9%87%91%E7%BA%B8.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ paperList.data[4].name }}</span
          ><br /><br />
          <span>{{ paperList.data[4].content }}</span>
        </div>
      </div>
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Paper/%E6%A9%98%E9%BB%84%E8%89%B2%E6%8F%8F%E9%87%91%E4%BA%91%E9%BE%99%E8%BE%B9%E7%B2%89%E8%9C%A1%E7%AC%BA.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ paperList.data[5].name }}</span
          ><br /><br />
          <span>{{ paperList.data[5].content }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Content",
  props: ["paperList"],
};
</script>
  
<style scoped>
#step {
  margin-left: 10%;
  margin-right: 10%;
}
.step {
  display: flex;
  margin: 0px 10px;
}
.picture {
  flex: 1;
  margin: 0px 1%;
}
.picture img {
  width: 100%;
  height: 30vh;
  object-fit: cover;
  object-position: center;
}
@media screen and (max-width: 2000px) {
  .picture img {
    height: 30vh;
  }
  .description {
    font-size: 15px;
  }
}
@media screen and (min-width: 2000px) {
  .picture img {
    height: 24vh;
  }
  .description {
    font-size: 18px;
  }
}
.description {
  margin-top: 10px;
  font-size: 13px;
}

@media screen and (max-width: 500px) {
  .step {
    display: block;
    margin: 2%;
  }
  .introduce {
    margin-top: 40px;
  }
  span {
    /* margin-top: 10px; */
    font-size: 11px;
  }
  .history .introduce {
    display: block;
  }
}
</style>
  