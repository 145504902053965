<template>
  <div id="introduce">
    <div class="technology-one">
      <div class="brew">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E4%B9%A6%E6%B3%95.jpg"
          alt=""
        />
      </div>

      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E7%8E%B3%E7%91%81%E7%AE%A1%E7%B4%AB%E6%AF%AB%E7%AC%94.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ quillList[0].name }}<br />
          <br />{{ quillList[0].content }}
        </div>
      </div>

      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E7%BA%A2%E6%BC%86%E6%8F%8F%E9%87%91%E5%A4%94%E5%87%A4%E7%BA%B9%E7%AE%A1%E5%85%BC%E6%AF%AB%E7%AC%94.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ quillList[1].name }}<br />
          <br />{{ quillList[1].content }}
        </div>
      </div>

      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E6%96%91%E7%AB%B9%E7%AE%A1%E7%8E%89%E7%AC%8B%E7%AC%94.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ quillList[2].name }}<br />
          <br />{{ quillList[2].content }}
        </div>
      </div>

      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E7%99%BD%E6%BD%A2%E6%81%AD%E8%BF%9B%E5%A4%A9%E5%AD%90%E4%B8%87%E5%B9%B4%E7%AC%94.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ quillList[3].name }}<br />
          <br />{{ quillList[3].content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Introduce",
  props: ["quillList"],
};
</script>

<style scoped>
#introduce {
  margin-left: 10%;
  margin-right: 10%;
}
.technology-one {
  display: flex;
  margin: 0px 4%;
  margin-top: 2%;
}
@media screen and (max-width: 2000px) {
  .technology-one {
    height: 60vh;
  }
}
@media screen and (min-width: 2000px) {
  .technology-one {
    height: 31vh;
  }
}
.technology-one > div {
  flex: 1;
  margin: 0 1%;
}
.technology-one .brew img {
  width: 100%;
  height: 100%;
}
.technology-one .histor {
  margin-top: 1%;
  position: relative;
}
.technology-one .histor .background {
  position: absolute;
  width: 100%;
  height: 45%;
}
.technology-one .introduce {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 50%;
  top: 47%;
  display: flex;
}
.technology-one .histor .background img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .brew {
    display: none;
  }
  .technology-one {
    display: block;
    height: auto;
  }
  .technology-one .histor {
    position: Static;
  }
  .technology-one .histor .background {
    position: Static;
    width: 100%;
    height: 45%;
  }
  .technology-one .introduce {
    position: Static;
  }
}
</style>