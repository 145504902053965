<template>
  <div id="app">
    <Title v-show="$route.meta.show"></Title>
    <router-view></router-view>
    <!-- <about-vue></about-vue> -->
  </div>
</template>

<script>
import aboutVue from './components/about/about.vue'
import login from "./components/Login/login.vue"
export default {
  name: 'App',
  components: {
    login,
    aboutVue
  },
  mounted() {
    if (localStorage.getItem("expireTime") < new Date().getTime()) {
      localStorage.removeItem("expireTime")
      localStorage.removeItem('jwt');
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }
  }
}
</script>

<style></style>
