<template>
  <div v-if="homeList.data">
    <div id="main">
      <h1>{{ homeList.data[inputName - 1].name }}</h1>
      <h2>{{ homeList.data[inputName - 1].title }}</h2>
      <p>{{ homeList.data[inputName - 1].text }}</p>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
export default {
  name: "Introduce",
  data() {
    return {
    };
  },
  props: {
    inputName: Number,
    required: true,
  },
  mounted() {
    this.$store.dispatch("homeList");
  },
  computed: {
    ...mapState({
      homeList: (state) => state.home.homeList,
    }),
  },
};
</script>

<style scoped>
div {
  color: white;
  font-family: "Ma+Shan+Zheng", cursive;
  /* box-shadow: 0px 0px 3px white; */
}

p {
  word-break: break-all;
  word-wrap: break-word;
  text-indent: 2em;
  font-size: 20px;
}

#main {
  width: 100%;
}
@media screen and (max-device-width: 800px) {
  #main {
    height: 43vh;
  }
  p {
    line-height: 1.4em;
    margin: 5px;
    font-size: 18px;
  }
  h1 {
    font-size: 21px;
    line-height: 1.2em;
  }
  h2 {
    font-size: 17px;
    line-height: 1.2em;
  }
}
h1 {
  text-align: center;
}
</style>