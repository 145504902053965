//配置路由的地方
import vue from 'vue'
import VueRouter from 'vue-router'
//使用插件
vue.use(VueRouter)
//引入路由组件
import Home from "@/components/Home/Home.vue"
import quill from "@/components/quill/quill.vue"
import Ink from "@/components/Ink/Ink.vue"
import Paper from "@/components/Paper/Paper.vue"
import Inkstone from "@/components/Inkstone/Inkstone.vue"
import NameList from '@/components/NameList/NameList.vue'
import Forum from '@/components/Forum/forum.vue'
import Map from "@/components/Map/mapComponent.vue"
import Login from '@/components/Login/login.vue'
import Register from '@/components/Register/register.vue'
import About from '@/components/about/about.vue'
//配置路由
export default new VueRouter({
    routes: [
        //主页
        {
            path: '/home',
            component: Home,
            meta: { show: true }
        },
        //笔
        {
            path: '/quill',
            component: quill,
            meta: { show: true }
        },
        //徽墨
        {
            path: '/ink',
            component: Ink,
            meta: { show: true }
        },
        // 宣纸
        {
            path: '/paper',
            component: Paper,
            meta: { show: true }
        },
        // 砚台
        {
            path: '/inkstone',
            component: Inkstone,
            meta: { show: true }
        },
        //名单
        {
            path: '/namelist',
            component: NameList,
            meta: { show: false }
        },
        //论坛
        {
            path: '/forum',
            component: Forum,
            meta: { show: false }
        },
        //地图——寻迹
        {
            path: '/map',
            component: Map,
            meta: { show: false }
        },
        //登录
        {
            path: '/login',
            component: Login,
            meta: { show: false }
        },
        //注册
        {
            path: '/register',
            component: Register,
            meta: { show: false }
        },
        //关于
        {
            path:'/about',
            component:About,
            meta: { show: false }
        },
        //重定向，在项目跑起来的时候，访问/，立马跳转到首页
        {
            path: '*',
            redirect: '/home'
        }

    ]
})