import Vue from 'vue'
import App from './App.vue'
//注册全局组件
//标题
import Title from '@/components/Title.vue'
import Title2 from '@/components/Title2.vue'
Vue.component(Title.name, Title)
Vue.component(Title2.name, Title2)
//引入仓库（Vuex）
import store from './stroe'
//引入路由
import router from './router/index'
//引入动画效果
import 'animate.css'
//引入element组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入bmap组件
import AmapVue from '@amap/amap-vue';
AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = '706c389fd08e0b29a31d84df30878cc3';
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
];
Vue.use(AmapVue);

Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  //注册仓库
  store,
  //注册路由
  router,
}).$mount('#app')
