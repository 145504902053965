<template>
  <div id="step">
    <div class="step">
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E8%9F%B9%E9%9D%92%E7%9F%B3%E7%A0%9A.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ inkstoneList.data[4].name }}</span
          ><br /><br />
          <span>{{ inkstoneList.data[4].introduce }}</span>
        </div>
      </div>
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E8%8F%8A%E8%8A%B1%E7%9F%B3%E9%9B%95%E4%BA%91%E9%BE%99%E7%BA%B9%E7%A0%9A.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ inkstoneList.data[5].name }}</span
          ><br /><br />
          <span>{{ inkstoneList.data[5].introduce }}</span>
        </div>
      </div>
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E6%9D%BE%E8%8A%B1%E7%9F%B3%E9%9B%95%E6%A1%83%E5%BD%A2%E7%A0%9A.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ inkstoneList.data[6].name }}</span
          ><br /><br />
          <span>{{ inkstoneList.data[6].introduce }}</span>
        </div>
      </div>
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E7%AB%AF%E7%9F%B3%E9%86%89%E7%BF%81%E7%A0%9A.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ inkstoneList.data[7].name }}</span
          ><br /><br />
          <span>{{ inkstoneList.data[7].introduce }}</span>
        </div>
      </div>
      <div class="picture">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E4%B8%89%E7%86%8A%E8%B6%B3%E7%9F%B3%E7%A0%9A.jpg"
          alt=""
        />
        <div class="description">
          <span>{{ inkstoneList.data[8].name }}</span
          ><br /><br />
          <span>{{ inkstoneList.data[8].introduce }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content",
  props: ["inkstoneList"],
};
</script>

<style scoped>
#step {
  margin-left: 10%;
  margin-right: 10%;
}

.step {
  display: flex;
  margin: 0px 10px;
}

.picture {
  flex: 1;
  margin: 0px 1%;
}

.picture img {
  width: 100%;
  height: 40%;
}

.description {
  margin-top: 10px;
  font-size: 13px;
}

/* @media screen and (max-width: 2000px) {
  .description {
    font-size: 15px;
    height: 15vh;
  }
}

@media screen and (min-width: 2000px) {
  .description {
    font-size: 18px;
    height: 9vh;
  }
} */

@media screen and (max-width: 500px) {
  .step {
    display: block;
    margin: 2%;
  }

  .description {
    margin-bottom: 20px;
  }

  span {
    /* margin-top: 10px; */
    font-size: 11px;
  }
}
</style>