<template>
  <div id="introduce">
    <div class="technology-one">
      <div class="brew">
        <img
          src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E5%BE%BD%E5%A2%A8.jpg"
          alt=""
        />
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E5%AD%99%E7%91%9E%E5%8D%BF%E7%A5%9E%E5%93%81%E5%A2%A8.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ InkList[0].name }}<br />
          <br />{{ InkList[0].origin }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E7%A8%8B%E5%90%9B%E6%88%BF%E8%9F%A0%E8%9E%AD%E7%BA%B9%E5%9C%86%E5%A2%A8.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ InkList[1].name }}<br />
          <br />{{ InkList[1].origin }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E5%90%B4%E5%8E%BB%E5%B0%98%E9%BE%99%E4%B9%9D%E5%AD%90%E5%A2%A8.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ InkList[2].name }}<br />
          <br />{{ InkList[2].origin }}
        </div>
      </div>
      <div class="histor">
        <div class="background">
          <img
            src="https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E9%87%91%E5%87%A4%E5%85%83%E7%8F%8D%E7%8F%A0%E9%A6%99%E5%A2%A8.jpg"
            alt=""
          />
        </div>
        <div class="introduce">
          {{ InkList[3].name }}<br />
          <br />{{ InkList[3].origin }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Introduce",
  props: ["InkList"],
};
</script>

<style scoped>
#introduce {
  margin-left: 10%;
  margin-right: 10%;
}

.technology-one {
  display: flex;
  margin: 0px 4%;
  margin-top: 2%;
}

@media screen and (max-width: 2000px) {
  .technology-one {
    height: 55vh;
  }
}

@media screen and (min-width: 2000px) {
  .technology-one {
    height: 32vh;
  }
}

.technology-one > div {
  flex: 1;
  margin: 0 1%;
}

.technology-one .brew img {
  width: 100%;
  height: 100%;
}

.technology-one .histor {
  margin-top: 1%;
  position: relative;
}

.technology-one .histor .background {
  position: absolute;
  width: 100%;
  height: 45%;
}

.technology-one .introduce {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 50%;
  top: 47%;
  display: flex;
}

.technology-one .histor .background img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .brew {
    display: none;
  }

  .technology-one {
    display: block;
    height: auto;
  }

  .technology-one .histor {
    position: Static;
  }

  .technology-one .histor .background {
    position: Static;
    width: 100%;
    height: 45%;
  }

  .technology-one .introduce {
    position: Static;
  }
}
</style>