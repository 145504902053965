//当前这个模块：对API进行统一管理
import home from "./home.js"
import Inkstone from "./Inkstone.js"
import Paper from "./Paper.js"
import quill from "./quill.js"
import Ink from "./Ink.js"
//首页文房四宝信息的介绍
export const reqhomeList=()=> home.get('/home/list')
//砚台介绍
export const reqInkstoneList=()=> Inkstone.get('/inkstone/list')
//宣纸介绍
export const reqPaperList=()=> Paper.get('/paper/list')
//毛笔介绍
export const reqquillList=()=> quill.get('/quill/list')
//徽墨介绍
export const reqInkList=()=> Ink.get('/ink/list')