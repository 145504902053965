<template>
  <div id="ink">
    <background></background>
    <template v-if="InkList && InkList.data">
      <Introduce :InkList="InkList.data"></Introduce>
      <Step :InkList="InkList.data"></Step>
    </template>
  </div>
</template>
  
  <script>
import Step from "../Ink/Step.vue";
import background from "../Ink/background.vue";
import Introduce from "../Ink/Introduce.vue";
import { mapState } from "vuex";
export default {
  name: "Ink",
  components: {
    background,
    Introduce,
    Step,
  },
  mounted() {
    this.$store.dispatch("inkList");
    console.log(this.InkList);
  },
  computed: {
    ...mapState({
      InkList: (state) => state.Ink.InkList,
    }),
  },
};
</script>
  
  <style scoped>
#ink {
  background-image: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/ink/%E8%83%8C%E6%99%AF%E5%9B%BE%E7%89%87.png");
  padding-bottom: 10px;
}
/* @media screen and (max-width: 2000px) {
  #ink {
    height: 185vh;
  }
}
@media screen and (min-width: 2000px) {
  #ink {
    height: 100vh;
  }
} */
</style>
  