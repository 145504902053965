<template>
  <div id="quill">
    <div id="bg"></div>
    <background></background>
    <template v-if="quillList && quillList.data">
      <Introduce :quillList="quillList.data"></Introduce>
      <quillContent :quillList="quillList.data"></quillContent>
    </template>
  </div>
</template>
  
  <script>
import quillContent from "./quillContent.vue";
import background from "../quill/background.vue";
import Introduce from "../quill/Introduce.vue";
import { mapState } from "vuex";
export default {
  name: "quill",
  components: {
    background,
    Introduce,
    quillContent,
  },
  mounted() {
    this.$store.dispatch("quillList");
    console.log(this.quillList);
  },
  computed: {
    ...mapState({
      quillList: (state) => state.quill.quillList,
    }),
  },
};
</script>
  
  <style scoped>
#quill {
  background-image: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/quill/%E8%83%8C%E6%99%AF%E5%9B%BE%E7%89%87.png");
  padding-bottom: 20px;
  min-height: calc(100vh - 10px);
  height: auto;
}
/* @media screen and (max-width: 2000px) {
  #quill {
    height: 185vh;
  }
} */
/* @media screen and (min-width: 2000px) {
  #quill {
    height: 100vh;
  }
} */
</style>
  