<template>
  <div id="inkstone">
    <background></background>
    <template v-if="InkstoneList && InkstoneList.data">
      <Introduce :inkstoneList="InkstoneList"></Introduce>
      <Step :inkstoneList="InkstoneList"></Step>
    </template>
  </div>
</template>

<script>
import background from "../Inkstone/background.vue";
import Introduce from "../Inkstone/Introduce.vue";
import Step from "../Inkstone/Step.vue";
import { mapState } from "vuex";
export default {
  name: "Inkstone",
  components: {
    background,
    Introduce,
    Step,
  },
  mounted() {
    this.$store.dispatch("inkstoneList");
    console.log(this.InkstoneList);
  },
  computed: {
    ...mapState({
      InkstoneList: (state) => state.Inkstone.InkstoneList,
    }),
  },
};
</script>

<style scoped>
#inkstone {
  background-image: url(https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Inkstone/%E8%83%8C%E6%99%AF%E5%9B%BE%E7%89%87.png);
  width: 100%;
  min-height: 100%;
  padding-bottom: 10px;
}
/* @media screen and (max-width: 2000px) {
  #inkstone {
    height: 130vh;
  }
}
@media screen and (min-width: 2000px) {
  #inkstone {
    height: 100vh;
  }
} */
</style>