<template>
  <div id="main" class="bg">
    <Title2></Title2>
    <br />
    <br />
    <div id="map">
      <amap
        v-if="areas[0]"
        :center="[areas[0].x, areas[0].y]"
        :zoom="zoom"
        :pitch.sync="pitch"
        :rotation.sync="rotation"
        view-mode="3D"
        map-style="amap://styles/whitesmoke"
      >
        <div v-for="(item, index) of areas" :key="index">
          <amap-info-window
            v-if="Window == index"
            :position="[item.x, item.y]"
            :offset="[0, -34]"
            is-custom
          >
            <div class="info-window-content">
              <a-card>
                <h3>{{ item.name }}</h3>
                <el-image
                  class="img"
                  style=""
                  :src="imgs[name][item.name]"
                ></el-image>
              </a-card>
            </div>
          </amap-info-window>

          <amap-marker
            :position="[item.x, item.y]"
            clickable
            @click="onMarkerClick(index)"
          ></amap-marker>
        </div>
      </amap>
    </div>
    <div class="content">
      <!-- <div>{{ center }}</div> -->
      <br />
      <div
        v-for="(paragraph, index) in paragraphs"
        :key="index"
        class="indented-paragraph"
      >
        {{ paragraph }}
      </div>
    </div>
  </div>
</template>
  
  <script>
import instance from "@/api/home";
export default {
  name: "mapComponent",
  data() {
    return {
      zoom: 7.5, // 设置地图缩放级别
      markerPosition: [], // 设置标记点位置经纬度
      content: "",
      areas: [],
      areaIds: [],
      pitch: 45,
      rotation: 15,
      Window: -1,
      imgs: {
        宣纸: {
          宣城市:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E5%AE%A3%E7%BA%B8.jpg",
        },
        徽墨: {
          黄山市:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E5%BE%BD%E5%A2%A8.jpg",
          宣城市:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E5%BE%BD%E5%A2%A82.jpg",
        },
        湖笔: {
          浙江湖州:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E6%B9%96%E7%AC%94.jpg",
        },
        侯笔: {
          河北省衡水市:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E4%BE%AF%E7%AC%94.jpg",
        },
        齐笔: {
          广饶县:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E9%BD%90%E7%AC%94.jpg",
        },
        端砚: {
          广东省肇庆市:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E7%AB%AF%E7%A0%9A.jpg",
        },
        歙砚: {
          歙州: "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E6%AD%99%E7%A0%9A.jpg",
        },
        澄泥砚: {
          山西绛州:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E6%BE%84%E6%B3%A5%E7%A0%9A.jpg",
        },
        宣笔: {
          宣城市:
            "https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E5%AE%A3%E7%AC%94.jpg",
        },
      },
      name: "",
    };
  },
  methods: {
    onMarkerClick(index) {
      if (this.Window == index) {
        this.Window = -1;
      } else {
        this.Window = index;
      }
    },
    getTrail() {
      instance
        .get("trail", {
          params: {
            name: this.name,
          },
        })
        .then((res) => {
          const data = res.data;
          this.content = data[0].content;

          for (let i = 0; i < data.length; i++) {
            this.areaIds.push(data[i].areaId);
          }

          for (let i = 0; i < data.length; i++) {
            this.getArea(data[i].areaId);
          }
        });
    },
    getArea(areaId) {
      instance.get("area/" + areaId).then((res) => {
        this.areas.push(res.data);
      });
    },
    // setCenter(areaId) {
    //   axios
    //     .get("http://172.16.36.38:8081/FourTreasuresForStudy/area/" + areaId)
    //     .then((res) => {
    //       this.center[0] = res.data.data.x;
    //       this.center[1] = res.data.data.y;
    //     });
    // },
  },

  watch: {
    $route() {
      this.name = this.$route.query.componentName; // 获取路由参数
      this.areaIds = []; // 清空之前的区域ID列表
      this.areas = []; // 清空之前的区域数据列表
      this.getTrail(); // 重新获取数据
    },
  },
  mounted() {
    this.name = this.$route.query.componentName;
    this.getTrail();
    this.areaIds.forEach((id) => {
      // 在这里对每个元素进行操作
      console.log(id);
    });

    for (let i = 0; i < this.areaIds.length; i++) {
      this.getArea(this.areaIds[i]);
    }
  },
  computed: {
    paragraphs() {
      return this.content.split("\\r");
    },
    isArea(){
      return this.areas[0]
    }
  },
};
</script>
  
  <style scoped>
/* 可选：添加样式 */

#main {
  height: 100vh;
  background: url("https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Map/%E8%83%8C%E6%99%AF%E5%9B%BE%E7%89%87.png");
}

.bg {
  background-color: rgb(228, 230, 216);
}

#map {
  width: 65%;
  margin: auto;
  height: 50vh;
}

.content {
  width: 65%;
  margin: auto;
  font-size: 19px;
  line-height: 1.6em;
}

.info-window-content {
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 2px #000;
  margin: auto;
}

.img {
  height: 12vh;
}

h3 {
  text-align: center;
}

.indented-paragraph {
  text-indent: 2em;
  /* 设置首行缩进为 2 个 em */
}

@media screen and (max-device-width: 500px) {
  #map {
    width: 70%;
    height: 40vh;
  }

  h3 {
    font-size: 17px;
  }

  .content {
    font-size: 15px;
    line-height: 1.4em;
    width: 70%;
  }

  .img {
    height: 8vh;
  }
}
</style>
  