<template>
  <div>
    <Content></Content>
  </div>
</template>

<script>
import Content from '../Home/Content.vue'
export default {
    name:"Home",
    components:{
        Content
    }
}
</script>

<style>

</style>