<template>
  <div id="paper">
    <background></background>
    <template v-if="paperList && paperList.data">
      <Introduce :paperList="paperList"></Introduce>
      <Step :paperList="paperList"></Step>
    </template>
  </div>
</template>

<script>
import background from "../Paper/background.vue";
import Introduce from "../Paper/Introduce.vue";
import Step from "../Paper/Step.vue";
import { mapState } from "vuex";
export default {
  name: "Paper",
  components: {
    background,
    Introduce,
    Step,
  },
  mounted() {
    this.$store.dispatch("paperList");
    console.log(this.paperList);
  },
  computed: {
    ...mapState({
      paperList: (state) => state.Paper.paperList,
    }),
  },
};
</script>

<style scoped>
#paper {
  background-image: url(https://four-treasures-for-study.oss-cn-hangzhou.aliyuncs.com/Paper/%E8%83%8C%E6%99%AF%E5%9B%BE%E7%89%87.png);
  width: 100%;
  min-height: 100%;
  padding-bottom: 10px;
}
/* @media screen and (max-width: 2000px) {
  #paper {
    height: 155vh;
  }
}
@media screen and (min-width: 2000px) {
  #paper {
    height: 100vh;
  }
} */
</style>